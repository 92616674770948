<template>
  <section class="tables">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body p-2">
            <b-table
              :items="items"
              id="table-list"
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="loading"
              show-empty
              bordered
              striped
            >
              <!-- FOR LOADING ANIMATIONS -->
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Chargement...</strong>
                </div>
              </template>
              <!-- SHOW IF TABLE IS EMPTY -->
              <template #empty >
                <div class="text-center text-gray">Table vide</div>
              </template>

              <template v-slot:cell(status)="data">
                <label>{{ data.value }}</label>
                <!-- 
                   :style="`color:${
                    data.value === 'En déploiement'
                      ? '#4287f5'
                      : data.value === 'En Attente'
                      ? '#a35837'
                      : data.value === 'Suspendu'
                      ? 'red'
                      : data.value === 'Terminé'
                      ? '#0f7316'
                      : 'gray'
                  };`"
                 -->
              </template>

              <template v-slot:cell(libelle)="data">
                <b-link
                  :to="{
                    name: 'show-vision',
                    params: {
                      slug: data.item.slug,
                      id: data.item.id,
                    },
                  }"
                >
                  <span v-html="data.value"></span>
                </b-link>
              </template>
              <template v-slot:cell(scoring)="data">
                <b-badge
                  v-if="data.value > 5"
                  variant="success"
                  class="text-success"
                  style="width: 30px; color: white !important"
                >
                  {{ data.value != 0 ? data.value : "" }}
                </b-badge>
                <b-badge
                  v-else-if="data.value >= 1 && data.value < 5"
                  variant="warning"
                  class="text-warning"
                  style="width: 30px; color: white !important"
                >
                  {{ data.value != 0 ? data.value : "" }}
                </b-badge>
                <b-badge
                  v-else
                  variant="danger "
                  class="text-danger"
                  style="width: 30px; color: white !important"
                >
                  {{ data.value != 0 ? data.value : "" }}
                </b-badge>
              </template>

              <template v-slot:cell(action)="data">
                <span v-html="data.value"></span>
              </template>

              <template v-slot:cell(avancement)="data">
                <b-progress
                  max="100"
                  show-progress
                  style="background-color: #cacccd !important"
                  height="15px"
                >
                  <b-progress-bar
                    :style="{ backgroundColor: variant(data.value) }"
                    :value="data.value"
                    :label="`${data.value}%`"
                  ></b-progress-bar>
                </b-progress>
              </template>

              <template v-slot:cell(deadline)="data">
                <div
                  style="
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                  "
                >
                  <div
                    style="
                      width: 20px;
                      height: 20px;
                      border-radius: 10px;
                      background: green;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <p style="position: relative; top: 8px; color: white">
                      {{ data.value }}
                    </p>
                  </div>
                </div>
              </template>

              <template v-slot:cell(responsable)="data">
                <TableResponsable :responsable="data.value" />
              </template>
              <template v-slot:cell(organisation)="data">
                <!-- {{ data.value }} -->
                <b-link
                  :to="{
                    name: 'show-organisation',
                    params: {
                      slug: data.value.slug || 'abcde',
                      id: data.value.id,
                    },
                  }"
                >
                  <span v-html="data.value.libelle"></span>
                </b-link>
              </template>
              <template v-slot:cell()> </template>

              <template v-slot:cell(task)="data">
                <a :href="data.value.link">{{ data.value.title }}</a>
              </template>

              <template v-slot:cell(objectifs)="data">
                <div>
                  <b-badge
                    v-if="data.value.length"
                    style="
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                      cursor: pointer;
                    "
                    pill
                    @click="$emit('showGoals', data.item)"
                  >
                    {{ data.value.length }}
                  </b-badge>
                  <b-badge
                    v-else
                    style="
                      background-color: #adb5bd;
                      border-color: #adb5bd;
                      cursor: pointer;
                    "
                    pill
                    >{{ data.value.length }}</b-badge
                  >
                </div>
              </template>

              <template v-slot:cell(actions)="data">
                <div
                  v-if="isAdmin || isSuper || isManager"
                  class="container ml-3 justify-content-end"
                >
                  <b-button
                    v-if="data.value.includes('edit')"
                    @click="$emit('editItem', data.item)"
                    variant="light"
                    pill
                    size="sm"
                    style="width: 40px; height: 40px"
                    class="d-flex align-items-center justify-content-center"
                    ><i class="mdi mdi-pencil-outline"></i
                  ></b-button>
                  <b-button
                    v-if="data.value.includes('delete')"
                    @click="$emit('deleteItem', data.item.id)"
                    variant="light"
                    pill
                    size="sm"
                    style="width: 40px; height: 40px"
                    class="d-flex align-items-center justify-content-center"
                    ><i class="mdi mdi-delete"></i
                  ></b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import { mapGetters } from "vuex";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableResponsable },
  data: function () {
    return {
      sortBy: "name",
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isManager"]),

    rows() {
      return this.items.length;
    },
  },
  props: {
    fields: Array,
    items: Array,
    loading: Boolean,
    totalItems: Number,
  },
  methods: {
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
  },
  watch: {
    // currentPage: function () {
    //   this.$emit("changePage", this.currentPage);
    // },
  },
};
</script>
