<template>
  <div id="app">
    <div
      style="border-bottom: 1px solid black"
      class="
        d-flex
        justify-content-between
        align-items-center
        mt-3
        flex-wrap
        pb-2
      "
    >
      <h4 class="card-title my-auto" style="font-size: 1.13rem">
        Liste des visions
      </h4>
      <div
        class="
          d-flex
          justify-content-end justify-content-center
          flex-wrap
          col-sm-12 col-md-auto
          p-0
          my-auto
        "
      >
        <b-form-group
          v-for="(it, ind) in filterationData"
          :key="ind + 'fil_flds'"
          class="ml-2 col-sm-12 col-md-auto p-0 mb-2 my-auto"
          label-for="input1"
        >
          <b-form-input
            v-if="it.type == 'txt'"
            type="email"
            :key="ind + ' inpo'"
            v-model="searchQuery[it.searchQuery]"
            :placeholder="it.placeholder"
          ></b-form-input>
          <v-select
            v-else-if="it.type == 'selectresponsable'"
            placeholder="Responsable"
            style="width: 200px"
            :options="responsables"
            v-model="responsable"
            :reduce="(responsable) => responsable.value"
            label="text"
          />

          <v-select
            v-else-if="it.type == 'select'"
            :style="`width:200px`"
            placeholder="Statut"
            :options="VISIONS_STATUS"
            v-model="searchQuery[it.searchQuery]"
          />
        </b-form-group>

        <CustomAddButton
          v-if="isAdmin || isSuper || isManager"
          ref="mdol"
          type="button"
          class="
            btn
            col-sm-12 col-md-auto
            test-btn
            btn-secondary
            d-flex
            align-items-center
            my-auto
            ml-2
          "
          text="Nouvelle vision"
          @click="$router.push({ name: 'add-vision' })"
        >
        </CustomAddButton>
      </div>
    </div>

    <VisionTable
      @editItem="$router.push({ name: 'edit-vision' })"
      @showGoals="showGoalsFuction"
      @deleteItem="deleteItem"
      class="mt-3"
      :fields="tableFields"
      :items="filteredVisions"
      :loading="loadingData"
      :totalItems="TOTAL_ITEMS"
      @changePage="changePage"
    />

    <div v-if="showAddVisionModal" to="modals-xyz-548">
      <Modal @close="showAddVisionModal = !showAddVisionModal">
        <AddVision />
      </Modal>
    </div>

    <div v-if="showEditVisionModal" to="modals-xyz-548">
      <Modal
        @close="showEditVisionModal = !showEditVisionModal"
        :action="'edit'"
      >
        <EditVision />
      </Modal>
    </div>

    <div v-if="showVisionModal" to="modals-xyz-548">
      <Modal
        @close="
          showVisi;
          onModal = !showVisionModal;
        "
        :item="{}"
        :action="'show'"
      >
        <ShowVision />
      </Modal>
    </div>

    <div v-if="showGoals" to="modals-xyz-548">
      <Modal @close="(showGoals = !showGoals), (selectedVision = null)">
        <goal-list :vision="selectedVision" />
      </Modal>
    </div>
  </div>
</template>

<style scoped>
.shdow {
  box-shadow: 1px 2px 6px -2px rgba(0, 0, 0, 0.88);
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}
</style>

<script>
import VisionTable from "./components/VisionTable.vue";
import GoalList from "./components/GoalList.vue";
import Modal from "@/components/custom/Modal.vue";
import { mapGetters } from "vuex";
// import { httpClient } from "@/helpers/services/HttpClient";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";

// import DropDownBox from '@/components/custom/DropDownBox';

export default {
  name: "visionScreen",
  data() {
    return {
      selectedVision: null,
      showGoals: false,
      loadingData: true,
      responsable: null,
      currentPage: 1,
      tableItems: [],
      tableFields: [
        { key: "libelle", label: "Vision", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "organisation", label: "Organisation", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        {
          key: "objectifs",
          label: "Objectif stratégique",
          sortable: true,
          //  thStyle: {textAlign:"center !important"},
        },
        // { key: "scoring", label: "Scoring", sortable: false },
        { key: "avancement", label: "Avancement", sortable: true },
        {
          key: "startedAt",
          label: "Date début",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "endingAt",
          label: "Date fin",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: { width: "150px !important", minWidth: "150px !important" },
        },
      ],
      searchQuery: {
        libelle: "",
        responsable: "",
        status: "",
        startedAt: "",
        endingAt: "",
      },
      modalLabel: "",
      showAddVisionModal: false,
      showEditVisionModal: false,
      showVisionModal: false,
      selectedItem: null,
      actions: ["show", "delete"],
      goalsTableList: [],
      filterationData: [
        {
          type: "txt",
          value: "",
          placeholder: "Libellé vision",
          searchQuery: "libelle",
        },
        {
          type: "selectresponsable",
          value: "",
          placeholder: "Responsable",
          searchQuery: "responsable",
        },
        {
          type: "select",
          value: "",
          placeholder: "Statut",
          searchQuery: "status",
        },
      ],
    };
  },
  components: {
    VisionTable,
    CustomAddButton,
    Modal,
    GoalList,
    // DropDownBox
  },
  methods: {
    changePage: function () {
      this.loadingData = true;
      this.$store
        .dispatch(
          "task/fetchAllVisions",
          `strategy/visions?page=${this.currentPage}`
        )
        .then(() => {
          this.loadingData = false;
          // this.tableItems = this.TASKS;
        });
    },
    showItem(item) {
      this.selectedItem = item;
      this.showVisionModal = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.showEditVisionModal = true;
    },

    showGoalsFuction(vision) {
      this.showGoals = !this.showGoals;
      this.selectedVision = vision;
      // const response = await httpClient.get(`strategy/visions/${visionId}/`);
      // this.goalsTableList = response.data;
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.tableItems = this.tableItems.filter((item) => item.id !== id);

          if (this.$store.dispatch("vision/deleteVision", id))
            Swal.fire("Supprimé!", "", "success");
        }
      });
    },
  },
  computed: {
    ...mapGetters("vision", ["VISIONS", "VISION", "TOTAL_ITEMS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("status", ["VISIONS_STATUS"]),
    ...mapGetters(["isAdmin", "isSuper", "isManager"]),
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    filteredVisions: function () {
      //  this.tableItems = this.VISIONS.map((item) => ({ ...item, actions: this.actions }));
      if (this.tableItems.length)
        return this.tableItems.filter(
          (vision) =>
            // vision.organisationResp &&
            vision.libelle
              .toLowerCase()
              .indexOf(this.searchQuery.libelle.toLowerCase()) > -1 &&
            (this.searchQuery.status
              ? vision.status
                  .toLowerCase()
                  .indexOf(this.searchQuery.status.toLowerCase()) > -1
              : true) &&
            // (vision.libelle.toLowerCase().indexOf(this.searchQuery.vision.toLowerCase()) > -1 )&&

            (this.responsable
              ? vision.responsable.id === parseInt(this.responsable)
              : true)
        );

      return [];
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    // this.$store.dispatch("vision/fetchAllVisions").then(() => {});
    this.$store.dispatch("vision/fetchAllData").then(() => {
      this.loadingData = false;
    });

    this.$store.dispatch("status/fetchVisionStatus");
  },
  mounted: function () {
    window.document.querySelectorAll(".ico_dm").forEach((sn_it) => {
      sn_it.addEventListener("click", (e) => {
        let tmpClasses = e.target.className.split(" ");
        if (tmpClasses.includes("icn_mod")) {
          this.newTask("Modifier la vision");
        } else if (tmpClasses.includes("icn_del")) {
          this.newTask("Supprimer la vision");
        } else if (tmpClasses.includes("icn_see")) {
          this.newTask("Fiche vision");
        }

        this.$refs.mdol.click();
      });
    });
  },
  watch: {
    currentPage: function () {
      this.changePage(this.currentPage);
    },
    VISIONS: function () {
      this.tableItems = this.VISIONS.map((item) => ({
        ...item,
        // avancement: Math.floor(Math.random() * 100),
        actions: this.actions,
      }));
    },
  },
};
</script>

<style>
.styleselect .vs__search::placeholder,
.styleselect .vs__dropdown-toggle,
.styleselect .vs__dropdown-menu {
  background: #ffffff;
  border: none;
  color: #9b9b9b;
  text-transform: lowercase;
  font-variant: small-caps;
}

.styleselect .vs__dropdown-toggle {
  height: 44px !important;
  width: 220px !important;
  border: 1px rgb(212, 212, 212) solid;
}

.styleselect .vs__clear,
.styleselect .vs__open-indicator {
  fill: #000000;
}
</style>
