<template>
  <section class="container-fluid bg-white p-0 rounded-lg">
    <div style="margin-top: -56px">
      <div class="col-10 p-0">
        <p class="h2 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>
          <span style="font-size: 18px" class="font-weight-light">Vision : </span>
          <span style="font-size: 22px">{{ vision.libelle }}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des objectifs stratégiques :</h4>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          :items="tableItems"
          id="table-list"
          responsive
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loading"
          show-empty
          bordered
          striped
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template v-slot:cell(organisationResp)="data">
            <span>{{ data.value.libelle }}</span>
          </template>

          <template v-slot:cell(kpis)="data">
            <span class="">{{ data.value.length ? data.value[0].name : "-" }}</span>
          </template>

          <template v-slot:cell(desription)="data">
            <div class="text-truncate" style="max-width: 100px">
              <span>{{ data.value ? data.value : "-" }}</span>
            </div>
          </template>

          <template v-slot:cell(feuilleRoutes)="data">
            <b-badge
              style="cursor: pointer; background-color: #adb5bd; border-color: #adb5bd"
              pill
              >{{ data.value.length }}</b-badge
            >
          </template>
          <template v-slot:cell(avancement)="data">
            <b-progress
              max="100"
              show-progress
              style="background-color: #cacccd !important"
              height="15px"
            >
              <b-progress-bar
                :style="{
                  backgroundColor: variant((data.value || 0).toFixed(2)),
                }"
                :value="(data.value || 0).toFixed(2)"
                :label="`${(data.value || 0).toFixed(2)}%`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template v-slot:cell(goal)="data">
            <div class="text-center d-flex justify-content-center">
              <span class="text-center p-0">{{ data.value }}</span>
            </div>
          </template>

          <template v-slot:cell(libelle)="data">
            <b-link
              :to="{
                name: 'show-objectif',

                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              >{{ data.value }}</b-link
            >
          </template>

          <template v-slot:cell(responsable)="data">
            <div>
              <span>
                {{ data.value.firstname + " " + data.value.lastname }}
              </span>
            </div>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import {mapGetters} from "vuex"
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  props: {
    vision: Object,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 5,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "libelle", label: "Objectif stratégique", sortable: true },
        { key: "organisationResp", label: "Organisation", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "desription", label: "Description", sortable: false },
        { key: "avancement", label: "Avancement", sortable: false },
        { key: "feuilleRoutes", label: "Alignement", sortable: true },
        { key: "kpis", label: "Kpis", sortable: false },
      ],
      loading : true
    };
  },
  methods: {
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "default";
    },
  },
  created(){
    // this.$store.dispatch('vision/fetchVisionObjectifs',this.vision.id).then(() => {
    //   this.loading = false
    // })
      this.$store.dispatch('vision/fetchVision',this.vision.id).then(() => {
      this.loading = false
    })
  },
  computed: {
    ...mapGetters("vision",["OBJECTIFS","VISION"]),
    tableItems() {
      // return this.OBJECTIFS?.objectifs?? []
      return this.VISION?.objectifs?? []
    },
    rows() {
      return this.tableItems.length;
    },

  },
};
</script>
