var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"tables"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-2"},[_c('b-table',{attrs:{"items":_vm.items,"id":"table-list","responsive":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.loading,"show-empty":"","bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(status)",fn:function(data){return [_c('label',[_vm._v(_vm._s(data.value))])]}},{key:"cell(libelle)",fn:function(data){return [_c('b-link',{attrs:{"to":{
                  name: 'show-vision',
                  params: {
                    slug: data.item.slug,
                    id: data.item.id,
                  },
                }}},[_c('span',{domProps:{"innerHTML":_vm._s(data.value)}})])]}},{key:"cell(scoring)",fn:function(data){return [(data.value > 5)?_c('b-badge',{staticClass:"text-success",staticStyle:{"width":"30px","color":"white !important"},attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(data.value != 0 ? data.value : "")+" ")]):(data.value >= 1 && data.value < 5)?_c('b-badge',{staticClass:"text-warning",staticStyle:{"width":"30px","color":"white !important"},attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(data.value != 0 ? data.value : "")+" ")]):_c('b-badge',{staticClass:"text-danger",staticStyle:{"width":"30px","color":"white !important"},attrs:{"variant":"danger "}},[_vm._v(" "+_vm._s(data.value != 0 ? data.value : "")+" ")])]}},{key:"cell(action)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.value)}})]}},{key:"cell(avancement)",fn:function(data){return [_c('b-progress',{staticStyle:{"background-color":"#cacccd !important"},attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:({ backgroundColor: _vm.variant(data.value) }),attrs:{"value":data.value,"label":`${data.value}%`}})],1)]}},{key:"cell(deadline)",fn:function(data){return [_c('div',{staticStyle:{"width":"100%","height":"100%","display":"flex","justify-content":"center"}},[_c('div',{staticStyle:{"width":"20px","height":"20px","border-radius":"10px","background":"green","display":"flex","align-items":"center","justify-content":"center"}},[_c('p',{staticStyle:{"position":"relative","top":"8px","color":"white"}},[_vm._v(" "+_vm._s(data.value)+" ")])])])]}},{key:"cell(responsable)",fn:function(data){return [_c('TableResponsable',{attrs:{"responsable":data.value}})]}},{key:"cell(organisation)",fn:function(data){return [_c('b-link',{attrs:{"to":{
                  name: 'show-organisation',
                  params: {
                    slug: data.value.slug || 'abcde',
                    id: data.value.id,
                  },
                }}},[_c('span',{domProps:{"innerHTML":_vm._s(data.value.libelle)}})])]}},{key:"cell()",fn:function(){return undefined},proxy:true},{key:"cell(task)",fn:function(data){return [_c('a',{attrs:{"href":data.value.link}},[_vm._v(_vm._s(data.value.title))])]}},{key:"cell(objectifs)",fn:function(data){return [_c('div',[(data.value.length)?_c('b-badge',{staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd","cursor":"pointer"},attrs:{"pill":""},on:{"click":function($event){return _vm.$emit('showGoals', data.item)}}},[_vm._v(" "+_vm._s(data.value.length)+" ")]):_c('b-badge',{staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd","cursor":"pointer"},attrs:{"pill":""}},[_vm._v(_vm._s(data.value.length))])],1)]}},{key:"cell(actions)",fn:function(data){return [(_vm.isAdmin || _vm.isSuper || _vm.isManager)?_c('div',{staticClass:"container ml-3 justify-content-end"},[(data.value.includes('edit'))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40px","height":"40px"},attrs:{"variant":"light","pill":"","size":"sm"},on:{"click":function($event){return _vm.$emit('editItem', data.item)}}},[_c('i',{staticClass:"mdi mdi-pencil-outline"})]):_vm._e(),(data.value.includes('delete'))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"40px","height":"40px"},attrs:{"variant":"light","pill":"","size":"sm"},on:{"click":function($event){return _vm.$emit('deleteItem', data.item.id)}}},[_c('i',{staticClass:"mdi mdi-delete"})]):_vm._e()],1):_vm._e()]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }